<template>
  <b-sidebar
    v-model="state"
    :width="`${width}px`"
    :title="$t('fAffiliate_Cust_title')"
    :no-header-close="true"
    right
    shadow
    no-close-on-esc
    no-close-on-backdrop
  >
    <b-card>
      <data-table
        v-if="itemId"
        ref="dt"
        class="flex-grow-1 pt-2 text-nowrap"
        repo="affiliates.users"
        :parent-id="itemId"
        :fields="detailFields"
      >
        <template #[`cell(up.display_name)`]="{ data: { item }}">
          <user-profile-item
            :user="item.user"
          />
        </template>
        <template #[`cell(sending_postback)`]="{ data: { item }}">
          <div class="text-center">
            <font-awesome-icon v-if="item.sent_at && item.error" :icon="['fas', 'circle']" class="text-danger" />
            <font-awesome-icon v-if="item.sent_at && !item.error" :icon="['fas', 'circle']" class="text-success" />
            <font-awesome-icon v-if="!item.sent_at" :icon="['fas', 'circle']" class="text-warning" />
          </div>
        </template>
        <template #[`cell(request)`]="{ data: { item }}">
          <div class="small text-left text-wrap" style="max-width: 260px;">
            {{ item.request }}
          </div>
        </template>
        <template #[`cell(response)`]="{ data: { item }}">
          <div class="small text-left text-wrap" style="max-width: 260px; ">
            {{ item.response }}
          </div>
        </template>
        <template #[`cell(utm_track)`]="{ data: { item }}">
          <div
            v-if="item && item.user && item.user.utm_track"
            class="small text-left text-break"
          >
            <span v-for="key in Object.keys(item.user.utm_track)" :key="key">
              <b class="text-uppercase ">{{ key }}</b> : {{ item.user.utm_track[key] }}<br>
            </span>
          </div>
        </template>
      </data-table>
    </b-card>
    <template #footer>
      <div class="d-flex bg-light align-items-center px-3 py-2">
        <strong class="mr-auto" />
        <b-btn variant="secondary" size="sm" @click="onCancel">
          {{ $t('eDoc_form_a_cancel') }}
        </b-btn>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions, mapGetters } from 'vuex'
import { isServer } from '@/mixins/helpers'

export default {
  name: 'AffiliateTestForm',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    idx: {
      type: Number,
      default: -1
    },
    itemId: {
      type: [Number, String],
      default: -1
    },
    url: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      busy: false,
      state: this.value
    }
  },
  computed: {
    ...mapGetters({
      deviceInfo: 'device'
    }),
    ...mapFields({
      postbackURL: 'forms.affiliateTest.postbackURL',
      params: 'forms.affiliateTest.params',
      method: 'forms.affiliateTest.method',
      error: 'forms.affiliateTest.error',
      response: 'forms.affiliateTest.response'
    }),
    isMobile () {
      return this.deviceInfo?.type === 'mobile' || false
    },
    isTablet () {
      return this.deviceInfo?.type === 'tablet' || false
    },
    width () {
      if (isServer) {
        return 0
      } else if (this.isMobile) {
        return document.body.offsetWidth
      } else if (this.isTablet) {
        return Math.round(document.body.offsetWidth * 0.90) - (15 * this.idx - 1)
      }
      return Math.round(document.body.offsetWidth * 0.80) - (15 * this.idx - 1)
    },
    detailFields () {
      return [
        {
          key: 'up.display_name',
          label: this.$t('fAffiliate_Cust_f_user'),
          sortable: true,
          thClass: 'col-3'
        },
        {
          key: 'register_at',
          label: this.$t('fAffiliate_Cust_f_registeredOn'),
          sortable: true,
          class: 'text-center',
          formatter: (value, key, item) => {
            return this.$dateFormat(item.created_at)
          }
        },
        {
          key: 'sending_postback',
          label: this.$t('fAffiliate_Cust_f_sendingPostback'),
          sortable: false,
          class: 'text-center'
        },
        {
          key: 'sent_at',
          label: this.$t('fAffiliate_Cust_f_deliveryTime'),
          sortable: true,
          class: 'text-center',
          formatter: (value, key, item) => {
            return this.$dateFormat(item.sent_at)
          }
        },
        {
          key: 'request',
          label: this.$t('fAffiliate_Cust_f_url'),
          sortable: true,
          thClass: 'text-center col-2'
        },
        {
          key: 'response',
          label: this.$t('fAffiliate_Cust_f_answer'),
          sortable: true,
          thClass: 'text-center col-2'
        },
        {
          key: 'utm_track',
          label: this.$t('fAffiliate_Cust_f_utm'),
          sortable: false,
          thClass: 'text-center col-2'
        }
      ]
    }
  },
  watch: {
    value (n) {
      this.state = n
    }
  },
  mounted () {
    this.postbackURL = this.url
  },
  beforeDestroy () {
    this.flush()
  },
  methods: {
    ...mapActions({
      send: 'forms/affiliateTest/send',
      flush: 'forms/affiliateTest/flush'
    }),
    onCancel () {
      this.$emit('input', false)
    },
    async onSend () {
      this.busy = true
      await this.send()
      this.busy = false
    }
  }
}
</script>

<style scoped>

</style>
